import React, { useState, useEffect, useContext, Component } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import axiosInstance from "../../../axiosInstance";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "react-image-lightbox/style.css"; // Import the required styles
import "react-image-lightbox/style.css"; // Import the required styles
import Lightbox from "react-image-lightbox";

const ViewHospital = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const [EnquireShow, setEnquireShow] = useState(false);
  const [enquireData, setEnquireData] = useState([]);

  const [EnquireStatus, setEnquireStatus] = useState(false);

  const enquireToggle = () => {
    if (EnquireShow) {
      setEnquireShow(false);
    } else {
      setEnquireShow(true);
    }
  };

  const images = [
    "https://www.apollohospitals.com/delhi/wp-content/uploads/2021/07/full/7.jpg",
    "https://www.apollohospitals.com/delhi/wp-content/uploads/2021/07/full/5.jpg",
  ];

  const [loading, setloading] = useState(true);

  const [Page, setPage] = useState({});
  const { slug } = useParams();

  const getDoctor = async () => {
    setloading(true);

    try {
      const { data } = await axiosInstance.get(`/get-user/${slug}`);
      setPage(data.Mpage);
      console.log(data);
      // Delay the execution of buildScript by 1 secon

      setEnquireData((prevData) => ({
        ...prevData,
        userId: data.Mpage._id,
      }));
    } catch (error) {
      console.log(error);
      // toast.error("Error fetching Single Blog!");
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    getDoctor();
    window.scrollTo(0, 0);
  }, [slug]);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const [formData, setFormData] = useState({
    userId: "",
    productId: "",
    rating: "",
    comment: "",
  });

  const validate = (email) => {
    // Regular expression to check for invalid characters
    const invalidCharsRegex = /[^\w@_.]| /; // Disallow spaces and any character that's not a word character, @, or .

    // Check for invalid cases
    if (email === "-" || email === "+") {
      return "";
    } else if (invalidCharsRegex.test(email)) {
      return ""; // Return empty string if invalid characters (including spaces) are found
    } else {
      return email; // Return the original email if valid
    }
  };

  const capitalizeEachWord = (str) => {
    if (!str) return str;
    str = str.replace(/\d+/g, "");
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const handleEnquireChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Determine the new value based on input type
    let newValue;
    if (type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }

    // Capitalize each word for specific fields
    if (name === "fullname") {
      newValue = capitalizeEachWord(newValue);
    }

    if (name === "email") {
      newValue = validate(newValue);
    }

    if (name === "phone") {
      // Replace non-digit characters
      newValue = value.replace(/\D/g, ""); // This allows only digits
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10); // Limit to 10 digits
      }
    }

    // Update form data with the new value
    setEnquireData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const [isOpenReview, setIsOpenReview] = useState(false);
  const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

  const handleSendEnquire = async (name, Id, userEmail) => {
    const fields = [
      { name: "fullname", message: "Please enter Full Name" },
      { name: "phone", message: "Please enter phone number" },
      { name: "email", message: "Please enter email" },
      // { name: "QTY", message: "Please enter Quantity" },
    ];

    for (const field of fields) {
      if (!enquireData[field.name]) {
        toast.error(field.message);
        setEnquireStatus(false);
        return;
      }
    }

    // Clear form fields

    const updatedFormData = {
      ...enquireData,
      userId: Id || null,
      userEmail: userEmail || null,
      type: 2,
      name,
    };

    try {
      setEnquireStatus(true);
      await toast.promise(
        axiosInstance.post(`/send-enquire`, updatedFormData),
        {
          loading: "Enquire Submiting...", // Loading message
          success: "Enquire Submited Successfully!", // Success message
          error: "Failed to Submit Enquire.", // Error message
        }
      );
      enquireToggle();
      setEnquireData((prevData) => ({
        ...prevData,
        fullname: "",
        email: "",
        phone: "",
        service: "",
        QTY: "",
        userId: null,
        Requirement: "",
      })); // Clear form fields
    } catch (error) {
      console.error("Failed to send email:", error);
      // toast.error('Failed to send email');
    } finally {
      setEnquireStatus(false);
    }
  };

  const toggleReviewPopup = () => {
    setIsOpenReview(!isOpenReview);

    // if (userId) {
    //   setIsOpenReview(!isOpenReview);
    // } else {
    //   toast.error("Please Login First", TotalQuantity);
    // }
  };

  return (
    <>
      <Header />
      {/* <Helmet>
        <title>{Page.metaTitle}</title>
        <meta name="metaDescription" content={Page.meta_description} />
      </Helmet> */}

      <main className="page whitesmoke">
        {/* Header */}
        {/* Header */}
        {/* Blog Title */}
        <div className="py-4">
          <div className="container d-lg-flex justify-content-between align-items-center py-2">
            <div className="pe-lg-4 text-center text-lg-start">
              <h1 className="h3 mb-0"> {!loading && Page.title} </h1>
            </div>
            <div className="pt-2 pt-lg-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/" previewlistener="true">
                      <i className="ri-store-2-line" /> Home{" "}
                    </Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link
                      className="text-nowrap"
                      to="/search/doctor"
                      previewlistener="true"
                    >
                      Doctors
                    </Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link
                      className="text-nowrap"
                      to="/"
                      disable
                      previewlistener="true"
                    >
                      {Page.username}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        {/* Blog Title */}
        {/* Blog List */}
        <div className="container ">
          <div className="row g-10 pb-5">
            {/* Blog List */}
            <div className="col-lg-12">
              {/* Article */}

              {!loading ? (
                <>
                  <div className="container card bg-white">
                    <div className="doctor-card col-md-12">
                      <div className="d-flex border-bottom gap-4 p-4">
                        <div className="doctor-profile">
                          <img
                            src={Page.profile}
                            width="100px"
                            className="rounded-circle"
                            alt={Page.username}
                            style={{ aspectRatio: "1/1" }}
                          />
                        </div>
                        <div className="doctor-details d-flex align-items-center gap-4 flex-1 w-100">
                          <div className="doctor-details-left flex-1 w-100">
                            <h4>{Page.username}</h4>
                            <span
                              className="badge text-bg-dark text-white mb-2"
                              style={{}}
                            >
                              {Page.specialization}
                            </span>
                            {Page.experience && (
                              <span className="badge text-bg-dark text-white ms-2 mb-2">
                                {Page.experience === 0 && "Intern"}
                                {Page.experience === 1 && "1-3 Year"}
                                {Page.experience === 3 && "3-4 Year"}
                                {Page.experience === 5 && "5+ Year"}
                                {Page.experience === 10 && "10+ Year"}
                                {Page.experience === 20 && "20+ Year"}
                              </span>
                            )}
                            <br />
                            <span className="badge text-bg-dark text-white d-inline-block">
                              <i className="ri-map-pin-2-fill" /> {Page.address}
                            </span>

                            <p className="mt-2">
                              <i class="ri-checkbox-circle-fill text-success"></i>{" "}
                              verified
                            </p>
                          </div>
                          <div className="doctor-details-right text-end">
                            <button
                              className="btn btn-dark btn-shadow d-block w-100 rounded-0"
                              data-toggle="modal"
                              data-target={`#exampleModalpop`}
                              onClick={enquireToggle}
                            >
                              Enquire Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal enquire fade show"
                    style={{
                      display: EnquireShow ? "block" : "none",
                    }}
                    id={`exampleModalpop`}
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header py-0">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Enquire Now
                          </h5>
                          <button
                            type="button"
                            className="border-0 close bg-transparent h3"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={enquireToggle}
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="d-block">
                            {/* Name input */}
                            <div
                              data-mdb-input-init=""
                              className="form-outline mb-4"
                            >
                              <label
                                className="form-label"
                                htmlFor="form5Example1"
                              >
                                Full Name{" "}
                                <span className="text-danger">
                                  {" "}
                                  (Required )
                                </span>
                              </label>
                              <input
                                type="text"
                                name="fullname"
                                id="form5Example1"
                                className="form-control"
                                value={enquireData.fullname}
                                onChange={handleEnquireChange}
                                style={{
                                  boxShadow: "none",
                                }}
                              />
                            </div>
                            {/* Email input */}
                            <div
                              data-mdb-input-init=""
                              className="form-outline mb-4"
                            >
                              <label
                                className="form-label"
                                htmlFor="form5Example2"
                              >
                                Phone No.{" "}
                                <span className="text-danger">
                                  {" "}
                                  (Required )
                                </span>
                              </label>
                              <input
                                type="number"
                                id="form5Example2"
                                className="form-control"
                                name="phone"
                                value={enquireData.phone}
                                onChange={handleEnquireChange}
                                style={{
                                  boxShadow: "none",
                                }}
                              />
                            </div>

                            <div
                              data-mdb-input-init=""
                              className="form-outline mb-4"
                            >
                              <label
                                className="form-label"
                                htmlFor="form5Example2"
                              >
                                Email address{" "}
                                <span className="text-danger">
                                  {" "}
                                  (Required )
                                </span>
                              </label>
                              <input
                                type="email"
                                id="form5Example2"
                                className="form-control"
                                name="email"
                                value={enquireData.email}
                                onChange={handleEnquireChange}
                                style={{
                                  boxShadow: "none",
                                }}
                              />
                            </div>

                            <div
                              data-mdb-input-init=""
                              className="form-outline mb-4"
                            >
                              <label
                                className="form-label"
                                htmlFor="form5Example2"
                              >
                                Requirement{" "}
                                <span className="text-danger">
                                  {" "}
                                  (Required )
                                </span>
                              </label>
                              <textarea
                                type="text"
                                id="Requirement"
                                className="form-control"
                                name="Requirement"
                                value={enquireData.Requirement}
                                onChange={handleEnquireChange}
                                style={{
                                  boxShadow: "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={enquireToggle}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() =>
                              handleSendEnquire(
                                Page.username,
                                Page._id,
                                Page.email
                              )
                            } // Wrap the function in an anonymous function
                            disabled={EnquireStatus} // Properly use the `disabled` attribute
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container card bg-white mt-4">
                    <nav>
                      <div
                        className="nav nav-tabs mb-3"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active"
                          id="nav-home-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-home"
                          type="button"
                          role="tab"
                          aria-controls="nav-home"
                          aria-selected="true"
                        >
                          Info
                        </button>
                        <button
                          className="nav-link"
                          id="nav-profile-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-profile"
                          type="button"
                          role="tab"
                          aria-controls="nav-profile"
                          aria-selected="false"
                        >
                          Gallery
                        </button>
                      </div>
                    </nav>
                    <div
                      className="tab-content p-3 border bg-light mb-2 "
                      id="nav-tabContent"
                    >
                      <div
                        className="tab-pane fade active show mb-2"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                      >
                        <p>{Page.about}</p>
                      </div>
                      <div
                        className="tab-pane fade mb-2"
                        id="nav-profile"
                        role="tabpanel"
                        aria-labelledby="nav-profile-tab"
                      >
                        <div className="gallery">
                          {images.map((image, index) => (
                            <img
                              key={index}
                              src={image}
                              alt={`image ${index}`}
                              onClick={() => openLightbox(index)}
                              style={{
                                width: "100px",
                                margin: "10px",
                                cursor: "pointer",
                              }}
                            />
                          ))}
                        </div>

                        {isOpen && (
                          <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={
                              images[
                                (photoIndex + images.length - 1) % images.length
                              ]
                            }
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                              setPhotoIndex(
                                (photoIndex + images.length - 1) % images.length
                              )
                            }
                            onMoveNextRequest={() =>
                              setPhotoIndex((photoIndex + 1) % images.length)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card p-4 mt-4">
                    <div className="mb-4">
                      <div>
                        <div className="mb-2">
                          <span className="mb-1 h4 d-block ">Reviews</span>
                          <span className="ms-0 mb-2 d-block">
                            {" "}
                            Help other customers make their decision{" "}
                          </span>

                          <button
                            className="btn btn-primary btn-sm d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={toggleReviewPopup}
                          >
                            <i className="ri-add-line me-1" />
                            Write a Review
                          </button>

                          {isOpenReview && (
                            <>
                              <div className="custom-popup ">
                                <div className="popup-container ">
                                  <button
                                    className="btn btn-danger btn-sm d-flex align-items-center justify-content-center"
                                    type="button"
                                    onClick={toggleReviewPopup}
                                  >
                                    <i className="ri-close-line" />
                                  </button>

                                  <div className="card border-0">
                                    <div className="card-body border-0">
                                      <h4 className="mb-2">
                                        Review And Rating
                                      </h4>
                                      <div className="needs-validation">
                                        <div className="mb-4">
                                          <label
                                            className="form-label"
                                            htmlFor="review-rating"
                                          >
                                            How was your experience
                                          </label>

                                          <div className="d-flex gap-2 ratingbox">
                                            <input
                                              type="radio"
                                              onChange={handleChange}
                                              name="rating"
                                              id="rating5"
                                              defaultValue="5"
                                              className="d-none"
                                            />
                                            <label htmlFor="rating5">
                                              <i className="ri-star-fill" />{" "}
                                            </label>

                                            <input
                                              type="radio"
                                              onChange={handleChange}
                                              name="rating"
                                              id="rating4"
                                              defaultValue="4"
                                              className="d-none"
                                            />
                                            <label htmlFor="rating4">
                                              <i className="ri-star-fill" />{" "}
                                            </label>

                                            <input
                                              type="radio"
                                              onChange={handleChange}
                                              name="rating"
                                              id="rating3"
                                              defaultValue="3"
                                              className="d-none"
                                            />
                                            <label htmlFor="rating3">
                                              <i className="ri-star-fill" />{" "}
                                            </label>

                                            <input
                                              type="radio"
                                              onChange={handleChange}
                                              name="rating"
                                              id="rating2"
                                              defaultValue="2"
                                              className="d-none"
                                            />
                                            <label htmlFor="rating2">
                                              <i className="ri-star-fill" />{" "}
                                            </label>

                                            <input
                                              type="radio"
                                              onChange={handleChange}
                                              name="rating"
                                              id="rating1"
                                              defaultValue="1"
                                              className="d-none"
                                            />
                                            <label htmlFor="rating1">
                                              <i className="ri-star-fill" />{" "}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="mb-4">
                                          <label
                                            className="form-label"
                                            htmlFor="review-text"
                                          >
                                            Review<span>*</span>
                                          </label>
                                          <textarea
                                            className="form-control"
                                            rows={5}
                                            maxLength={300}
                                            name="comment"
                                            id="review-text"
                                            value={formData.comment}
                                            onChange={handleChange}
                                          />
                                          <div className="invalid-feedback">
                                            Please leave your review.
                                          </div>
                                        </div>

                                        {SubmitLoading ? (
                                          <button
                                            className="btn btn-primary"
                                            type="button"
                                            onClick={"submitData"}
                                          >
                                            Add Review
                                          </button>
                                        ) : (
                                          <button
                                            class="btn btn-secondary btn-sm"
                                            type="button"
                                            disabled
                                          >
                                            <span class="ms-1">Loading...</span>
                                            <span
                                              class="spinner-border spinner-border-sm"
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                        <hr />

                        <span className="mb-3 h4 d-block ">
                          Customer Reviews
                        </span>
                      </div>
                      <div className="row g-4 g-md-10">
                        <div className="col-12">
                          <div className="card border-0 mb-5">
                            <div className="card-body">
                              <div className="row g-0">
                                <div className="col-auto">
                                  <span className="blog-article-meta-link">
                                    <div className="avatar">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        style={{
                                          isolation: "isolate",
                                          transform: "scale(0.6)",
                                        }}
                                      >
                                        <defs>
                                          <clipPath id="a">
                                            <rect width={64} height={64} />
                                          </clipPath>
                                        </defs>
                                        <g clipPath="url(#a)">
                                          <path d="M19.247 18.468C17.383 11.511 21.584 4.331 28.622 2.445 35.66.559 42.888 4.677 44.753 11.634 46.617 18.592 42.416 25.772 35.378 27.658 28.34 29.544 21.112 25.426 19.247 18.468zM44.003 31.262C53.176 33.928 61.012 43.833 61.012 55.553L61.012 58.776C61.012 60.556 59.567 62 57.788 62L6.212 62C4.433 62 2.988 60.556 2.988 58.776L2.988 55.553C2.988 43.833 10.824 33.928 19.997 31.262 20.851 31.014 21.986 31.384 22.53 32.088 23.946 33.922 27.739 35.922 32 35.922 36.261 35.922 40.054 33.922 41.47 32.088 42.014 31.384 43.149 31.014 44.003 31.262z" />
                                        </g>
                                      </svg>
                                    </div>
                                  </span>
                                </div>
                                <div className="col">
                                  <h6 className="mb-0 fs-base text-capitalize">
                                    manish Kumar
                                  </h6>
                                  <span
                                    className={`star-rating star-${4 * 2}`}
                                  />{" "}
                                  <span className="ms-2">12 may 2024</span>{" "}
                                  {/* Assuming you have a function formatDate() to format date */}
                                  <p className="fs-md mb-2"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h1> Loading....</h1>
                </>
              )}
            </div>
            {/* Blog List */}
          </div>
        </div>
        {/* Blog List*/}
      </main>

      <div className="site-cover site-cover-sm same-height overlay single-page d-none">
        {" "}
        {Page.title}
        <div className="container">
          <div className="row same-height justify-content-center">
            <div className="col-md-6">
              <div className="post-entry text-center">
                <h1 className="mb-4"> {Page.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section d-none">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: Page.description }} />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ViewHospital;
