import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInstance";

const MyBlogs = () => {
  const [blog, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserBlogs = async () => {
    try {
      const id = localStorage.getItem("userId");
      const { data } = await axiosInstance.get(`/all-blogs`);
      console.log(id);
      if (data?.success) {
        setBlogs(data?.blogs);
      }
      setIsLoading(false); // Set loading state to false after fetching data
    } catch (error) {
      console.log(error);
      setIsLoading(false); // Set loading state to false in case of an error
    }
  };

  useEffect(() => {
    getUserBlogs();
  }, []); // Empty dependency array ensures that the effect runs once after the initial render

  useEffect(() => {
    // This useEffect runs whenever the 'blog' state variable changes
    console.log(blog); // Log the updated 'blog' state
  }, [blog]); // Dependency array with 'blog' ensures the effect runs when 'blog' state changes

  return (
    <>
      <Header />

      <section className="section posts-entry posts-entry-sm bg-light">
        <div className="container">
          <h1 class="py-4">My Blogs</h1>
          <div className="row">
            {isLoading
              ? // Display loading skeletons while data is being fetched
                Array.from({ length: 4 }).map((_, index) => (
                  <div className="col-md-6 col-lg-3" key={index}>
                    <div className="blog-entry mb-4">
                      <div
                        className="skeleton mb-3"
                        style={{ height: 200, borderRadius: 10 }}
                      ></div>

                      <p
                        className="skeleton"
                        style={{ width: 130, borderRadius: 5 }}
                      >
                        {" "}
                      </p>
                      <p
                        className="skeleton"
                        style={{ width: 150, borderRadius: 5 }}
                      >
                        {" "}
                      </p>
                      <p
                        className="skeleton"
                        style={{ width: 170, borderRadius: 5 }}
                      >
                        {" "}
                      </p>
                      <p
                        className="skeleton"
                        style={{ width: 110, borderRadius: 5 }}
                      >
                        {" "}
                      </p>
                    </div>
                  </div>
                ))
              : blog.map((blog) => (
                  <div className="col-md-6 col-lg-3 pb-4" key={blog._id}>
                    <div className="card overflow-hidden">
                      <Link
                        to={`/blog/${blog.slug}`}
                        className="img-link rounded position-relative d-flex align-items-center justify-content-center "
                        style={{ aspectRatio: "1/0.7", objectFit: "cover" }}
                      >
                        <div
                          class="spinner-border position-absolute z-index-1"
                          role="status"
                        ></div>
                        <img
                          src={blog.image}
                          className="img-fluid bg-white z-index-2 position-relative"
                          style={{ aspectRatio: "1/0.7", objectFit: "cover" }}
                        />
                      </Link>
                      <div class="p-4">
                        <h4>
                          <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
                        </h4>
                        <p>
                          <Link to={`/blog/${blog.slug}`} class="btn border">
                            Continue Reading
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default MyBlogs;
