import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axiosInstance from "../../../axiosInstance";

const SearchDoctor = () => {
  const initialFilterData = {
    gender: null,
    experience: null,
    specialty: "",
    location: "",
    shortby: null,
  };

  const [filterInputs, setFilterInputs] = useState(initialFilterData);
  const [doctorList, setDoctorList] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Handle change in the search filters
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFilterInputs((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    // If gender or experience changes, trigger search
    if (filterInputs.gender || filterInputs.experience) {
      handleSearchClick();
    }
  }, [filterInputs.gender, filterInputs.experience]); // Trigger only when gender or experience changes

  // Search function triggered when the user clicks the "Search" button
  const handleSearchClick = () => {
    const queryParams = new URLSearchParams();

    // Add the filters to the query string
    if (filterInputs.location)
      queryParams.set("location", filterInputs.location);
    if (filterInputs.specialty)
      queryParams.set("specialty", filterInputs.specialty);
    if (filterInputs.gender) queryParams.set("gender", filterInputs.gender);
    if (filterInputs.experience)
      queryParams.set("experience", filterInputs.experience);
    if (filterInputs.shortby) queryParams.set("shortby", filterInputs.shortby);

    // Change the route and update the URL with the filter query parameters
    navigate({
      pathname: "/search/doctor", // Update this to your desired route
      search: queryParams.toString(),
    });
  };

  // Fetch doctors based on query parameters in the URL
  const searchDoctors = async () => {
    setLoading(true);

    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);

    const params = {
      location: queryParams.get("location") || "",
      specialty: queryParams.get("specialty") || "",
      gender: queryParams.get("gender") || null,
      experience: queryParams.get("experience") || null,
      shortby: queryParams.get("shortby") || null,
    };

    setFilterInputs((prevData) => ({
      ...prevData,
      location: queryParams.get("location") || "",
      specialty: queryParams.get("specialty") || "",
      gender: queryParams.get("gender") || null,
      experience: queryParams.get("experience") || null,
      shortby: queryParams.get("shortby") || null,
    }));

    try {
      const response = await axiosInstance.get("/all-doctor", { params });
      setDoctorList(response.data.users); // Assuming the API returns a list of doctors
      console.log(response.data.users); // Assuming the API returns a list of doctors
    } catch (error) {
      // toast.error("Failed to fetch doctors, please try again.");
      console.error(error);
      setDoctorList([]); // Assuming the API returns a list of doctors
    } finally {
      setLoading(false);
    }
  };

  // Fetch doctors when the component is mounted or the URL parameters change
  useEffect(() => {
    searchDoctors();
  }, [location.search]); // Re-fetch when search query in URL changes

  return (
    <>
      <Header />

      <main className="page">
        <div className="container">
          <div className="search-header row mt-4 justify-content-center px-3">
            <div className="d-flex mb-3 border px-2 col-md-4">
              <span className="p-3 px-0" id="inputGroup-sizing-default">
                <i className="ri-map-pin-line"></i>
              </span>
              <input
                type="search"
                className="form-control border-0 shadow-none"
                placeholder="Search Location"
                name="location"
                value={filterInputs.location}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex mb-3 border px-2 col-md-4">
              <span className="p-3 px-0" id="inputGroup-sizing-default">
                <i className="ri-search-line"></i>
              </span>
              <input
                type="search"
                className="form-control border-0 shadow-none"
                placeholder="Search Specialty"
                name="specialty"
                value={filterInputs.specialty}
                onChange={handleChange}
              />
            </div>

            <div className="d-flex mb-3 col-md-2 px-0">
              <button
                className="btn btn-dark btn-shadow d-block w-100 rounded-0"
                onClick={handleSearchClick} // Trigger the search when the button is clicked
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div className="searchfilter border-top border-bottom bg-light p-2 mb-4">
          <div className="container">
            <div className="row custom-filter">
              {/* Gender Filter */}
              <div className="dropdown col-md-2">
                <button
                  className="btn btn-outline-secondary dropdown-toggle rounded-0 bg-white text-dark w-100 filter"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filterInputs.gender
                    ? (filterInputs.gender === "1" && "Male Doctor") ||
                      (filterInputs.gender === "2" && "Female Doctor")
                    : "Gender"}
                </button>
                <ul className="dropdown-menu">
                  <li className="border-bottom ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="maledoctor"
                        value="1"
                        checked={filterInputs.gender === "1"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="maledoctor"
                      >
                        Male Doctor
                      </label>
                    </div>
                  </li>
                  <li className="ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="gender"
                        id="Femaledoctor"
                        value="2"
                        checked={filterInputs.gender === "2"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="Femaledoctor"
                      >
                        Female Doctor
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Experience Filter */}
              <div className="dropdown col-md-2">
                <button
                  className="btn btn-outline-secondary dropdown-toggle rounded-0 bg-white text-dark w-100 filter"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {filterInputs.experience
                    ? (filterInputs.experience === "1" &&
                        " +5 Year of Experience") ||
                      (filterInputs.experience === "3" &&
                        " +3 Year of Experience") ||
                      (filterInputs.experience === "5" &&
                        " +5 Year of Experience") ||
                      (filterInputs.experience === "10" &&
                        " +10 Year of Experience") ||
                      (filterInputs.experience === "20" &&
                        " +20 Year of Experience")
                    : "Experience"}
                </button>
                <ul className="dropdown-menu">
                  <li className="border-bottom ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="experience"
                        id="experience11"
                        value="1"
                        checked={filterInputs.experience === "1"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="experience11"
                      >
                        +1 Year of Experience
                      </label>
                    </div>
                  </li>

                  <li className="border-bottom ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="experience"
                        id="experience12"
                        value="3"
                        checked={filterInputs.experience === "3"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="experience12"
                      >
                        +3 Year of Experience
                      </label>
                    </div>
                  </li>

                  <li className="border-bottom ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="experience"
                        id="experience1"
                        value="5"
                        checked={filterInputs.experience === "5"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="experience1"
                      >
                        +5 Year of Experience
                      </label>
                    </div>
                  </li>
                  <li className="ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="experience"
                        id="experience2"
                        value="10"
                        checked={filterInputs.experience === "10"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="experience2"
                      >
                        +10 Year of Experience
                      </label>
                    </div>
                  </li>
                  <li className="ps-4">
                    <div className="dropdown-item form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="experience"
                        id="experience3"
                        value="20"
                        checked={filterInputs.experience === "20"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label cursor"
                        htmlFor="experience3"
                      >
                        +20 Year of Experience
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              {/* Sorting Filter */}
              <div className="col-md-3 d-flex align-items-center ms-auto">
                <span className="text-dark d-block w-25"> Sort By </span>
                <div className="dropdown w-100">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle rounded-0 bg-white text-dark w-100 filter"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {filterInputs.shortby
                      ? (filterInputs.shortby === "1" &&
                          "Experience - High to Low") ||
                        (filterInputs.shortby === "2" &&
                          "Consultation Fee - High to Low") ||
                        (filterInputs.shortby === "3" &&
                          "Consultation Fee - Low to High")
                      : "Relevance"}
                  </button>
                  <ul className="dropdown-menu">
                    <li className="border-bottom ps-4">
                      <div className="dropdown-item form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="shortby"
                          id="relevance1"
                          value="1"
                          checked={filterInputs.shortby === "1"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label cursor"
                          htmlFor="relevance1"
                        >
                          Experience - High to Low
                        </label>
                      </div>
                    </li>
                    <li className="ps-4 border-bottom">
                      <div className="dropdown-item form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="shortby"
                          id="relevance2"
                          value="2"
                          checked={filterInputs.shortby === "2"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label cursor"
                          htmlFor="relevance2"
                        >
                          Consultation Fee - High to Low
                        </label>
                      </div>
                    </li>

                    <li className="ps-4">
                      <div className="dropdown-item form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="shortby"
                          id="relevance3"
                          value="3"
                          checked={filterInputs.shortby === "3"}
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label cursor"
                          htmlFor="relevance3"
                        >
                          Consultation Fee - Low to High
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container doctor-search-result">
          <h2>{doctorList.length} doctors found</h2>

          <hr />

          <div className="doctor-list row">
            {loading ? (
              <>
                <div className="doctor-card col-md-6">
                  <div className="d-flex border-bottom gap-4 p-4">
                    <div className="doctor-profile">
                      <div
                        className="skeleton w-100 rounded-circle"
                        style={{
                          width: "100px",
                          maxWidth: "100%",
                          height: "100px",
                          aspectRatio: "1/1",
                        }}
                      ></div>
                    </div>
                    <div className="doctor-details d-flex align-items-center gap-4 flex-1 w-100">
                      <div className="doctor-details-left flex-1 w-100">
                        <div
                          className="skeleton  "
                          style={{
                            height: "30px",
                            width: "50%",
                          }}
                        />
                        <span
                          className="d-inline-block skeleton mt-3 "
                          style={{
                            height: "25px",
                            width: "70px",
                          }}
                        />{" "}
                        <span
                          className=" d-inline-block  skeleton  mt-3 "
                          style={{
                            height: "25px",
                            width: "50px",
                          }}
                        />{" "}
                        <span
                          className="d-inline-block  skeleton  mt-3"
                          style={{
                            height: "25px",
                            width: "50px",
                          }}
                        />
                        <br />
                        <div
                          className="skeleton  mt-2"
                          style={{
                            height: "30px",
                            width: "50%",
                          }}
                        />
                      </div>

                      <div className="doctor-details-right text-end">
                        <div
                          className="skeleton   mt-2"
                          style={{
                            height: "40px",
                            width: "150px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="doctor-card col-md-6">
                  <div className="d-flex border-bottom gap-4 p-4">
                    <div className="doctor-profile">
                      <div
                        className="skeleton w-100 rounded-circle"
                        style={{
                          width: "100px",
                          maxWidth: "100%",
                          height: "100px",
                          aspectRatio: "1/1",
                        }}
                      ></div>
                    </div>
                    <div className="doctor-details d-flex align-items-center gap-4 flex-1 w-100">
                      <div className="doctor-details-left flex-1 w-100">
                        <div
                          className="skeleton  "
                          style={{
                            height: "30px",
                            width: "50%",
                          }}
                        />
                        <span
                          className="d-inline-block skeleton mt-3 "
                          style={{
                            height: "25px",
                            width: "70px",
                          }}
                        />{" "}
                        <span
                          className=" d-inline-block  skeleton  mt-3 "
                          style={{
                            height: "25px",
                            width: "50px",
                          }}
                        />{" "}
                        <span
                          className="d-inline-block  skeleton  mt-3"
                          style={{
                            height: "25px",
                            width: "50px",
                          }}
                        />
                        <br />
                        <div
                          className="skeleton  mt-2"
                          style={{
                            height: "30px",
                            width: "50%",
                          }}
                        />
                      </div>

                      <div className="doctor-details-right text-end">
                        <div
                          className="skeleton   mt-2"
                          style={{
                            height: "40px",
                            width: "150px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              doctorList.map((doctor) => (
                <div className="doctor-card col-md-6" key={doctor.id}>
                  <div className="d-flex border-bottom gap-4 p-4">
                    <div className="doctor-profile">
                      {doctor.profile ? (
                        <img
                          src={doctor.profile}
                          width="100px"
                          className="rounded-circle"
                          alt={doctor.name}
                        />
                      ) : (
                        <img
                          src={"/assets/img/placeholder.png"}
                          width="100px"
                          className="rounded-circle"
                          alt={doctor.name}
                        />
                      )}
                    </div>
                    <div className="doctor-details d-flex align-items-center gap-4 flex-1 w-100">
                      <div className="doctor-details-left flex-1 w-100">
                        <h4>{doctor.username}</h4>
                        <span className="badge text-dark border mb-2">
                          {doctor.specialty} surgeon
                        </span>
                        <span className="badge text-dark border ms-2 mb-2">
                          +{doctor.experience} years experience
                        </span>
                        <br />
                        <span className="badge text-bg-primary text-white d-inline-block">
                          <i className="ri-map-pin-2-fill"></i>{" "}
                          {doctor.statename}
                        </span>
                      </div>

                      <div className="doctor-details-right text-end">
                        <Link
                          className="btn btn-dark btn-shadow d-block w-100 rounded-0"
                          to={`/doctor/${doctor.profile_url}`}
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default SearchDoctor;
