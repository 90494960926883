import { useRef, useState, useEffect, React } from "react";
import { useBlogContext } from "../../fetchdata/BlogContext";
import Header from "../components/Header"; // Replace this with your Header component
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import axiosInstance from "../../axiosInstance";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import RangeSlider from "../components/extra/RangeSlider";
import { Helmet } from "react-helmet";
import CreateSlug from "../components/extra/CreateSlug";

const Category = () => {
  const [enquireData, setEnquireData] = useState([]);

  const [layout, setLayout] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isProducts, setIsProducts] = useState(true);

  const [RProducts, setRProducts] = useState([]);

  const getProducts = async () => {
    try {
      const { data } = await axiosInstance.get("/all-home-products");
      setRProducts(data.products);
      setIsProducts(false); // Set loading state to false in case of an error
    } catch (error) {
      console.error("Error fetching products:", error);
      setIsProducts(false); // Set loading state to false in case of an error
    }
  };

  const getData = async () => {
    try {
      const { data } = await axiosInstance.get(`/home-layout-data`);
      setLayout(data.homeLayout);
      setIsLoading(false); // Set loading state to false in case of an error
    } catch (error) {
      console.log(error);
      toast.error("Error fetching Home layout!");
      setIsLoading(false); // Set loading state to false in case of an error
    }
  };

  useEffect(() => {
    getData();
    getProducts();
  }, []);

  const startMin = 0;
  const startMax = 100;
  const min = 0;
  const max = 200;
  const step = 1;

  const [selectedRanges, setSelectedRanges] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [Cat, setCat] = useState([]);
  const [CatT, setTCat] = useState([]);
  const [CatImage, setTCatImage] = useState("");

  const [CatM, setMCat] = useState([]);

  const [Pro, setPro] = useState([]);
  const [Attr, setAttr] = useState([]);
  const [fillter, setfillter] = useState("");

  const [Viewfillter, setViewfillter] = useState([]);

  const [isCat, setIsCat] = useState(true);
  const [count, setcount] = useState("100");
  const [Sidebar, setIsSidebar] = useState(false);

  const handlecountChange = (event) => {
    setcount(event.target.value);
  };

  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [ratings, setRatings] = useState([]);

  const getRating = async () => {
    try {
      const { data } = await axiosInstance.get(`/all-rating`);
      setRatings(data.ratings);
      // console.log('getRating', data)
    } catch (error) {
      console.error("Error fetching rating:", error);
    }
  };

  const [Specifications, setspecifications] = useState([]);

  const getCategory = async (fillter, page, price) => {
    setIsSidebar(false);
    try {
      window.scrollTo(0, 0);
      setIsCat(true);
      const encodedFilter = encodeURIComponent(fillter); // Encode the filter string

      const { data } = await axiosInstance.get(
        `/all/category-slug/${id}?filter=${encodedFilter}&price=${price}&page=${page}&perPage=${count}`
      );
      setCat([]);
      setTCat([]);
      setMCat([]);
      if (data?.success) {
        setCat(data?.categories);
        setTCat(data.MainCat);
        console.log("data.MainCat", data.MainCat);
        setEnquireData((prevData) => ({
          ...prevData,
          service: data.MainCat?.title,
        })); // Clear form fields
        setspecifications(data.MainCat.specifications.specifications || []);

        setTCatImage(data.MainCat.image);
        setTotalPages(data?.proLength);
        setfillter(fillter);

        setProducts(data?.productsFilter);

        setPro(data?.products);
        setMCat(data?.MainCat);
      }

      // console.log('datadatadata', data)
    } catch (error) {
      console.log(error);
      toast.error("Error fetching Category!");
    } finally {
      setIsCat(false);
    }
  };

  const Opensidebar = async () => {
    setIsSidebar(true);
  };
  const Closesidebar = async () => {
    setIsSidebar(false);
  };

  const getAttribute = async () => {
    try {
      const { data } = await axiosInstance.get("/all-attribute");
      // console.log("attr", data);
      setAttr(data.Attribute);
    } catch (error) {
      console.error("Error fetching attributes:", error);
    }
  };

  // Function to handle checkbox selection
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // console.log('valuevaluevalue', value)
    setSelectedFilters((prevState) => {
      // If checked, add the value to selectedFilters, otherwise remove it
      if (checked) {
        return { ...prevState, [value]: checked };
      } else {
        const { [value]: removedValue, ...rest } = prevState;
        return rest;
      }
    });

    // console.log('selectedFilters', selectedFilters)
    setCurrentPage(1);
  };

  const removeFilter = ({ key, val }) => {
    const value = `${key},${val}`;
    setSelectedFilters((prevState) => {
      const { [value]: removedValue, ...rest } = prevState;
      return rest;
    });

    setCurrentPage(1); // Set current page to 1
  };

  const generateQueryString = () => {
    const filterObj = {};

    // Construct filter object from selected filters
    Object.entries(selectedFilters).forEach(([key, value]) => {
      const [attrKey, attrValue] = key.split(",");

      // Check if attribute key already exists in filter object
      if (filterObj[attrKey]) {
        // Append to existing attribute value if key exists
        filterObj[attrKey] += `,${attrValue}`;
      } else {
        // Create new attribute if key doesn't exist
        filterObj[attrKey] = attrValue;
      }
    });

    // Convert filter object to JSON string
    const filterString = JSON.stringify(filterObj);

    // console.log(filterString); // Output the generated filter string
    setViewfillter(filterString);
    setTimeout(function () {
      getCategory(filterString, currentPage, selectedRanges);
    }, 100);
  };

  // const handleCategoryLinkClick = (categoryId) => {
  //   getCategory(fillter, currentPage, selectedRanges);
  // };

  useEffect(() => {
    generateQueryString(); // Call generateQueryString when selectedFilters change
    getRating();
    setIsCat(true); // Set loading state to true
    getAttribute();
    // getCategory(fillter,currentPage,selectedRanges);
  }, [id, currentPage, selectedFilters, selectedRanges, count]); // Empty dependency array ensures that the effect runs once after the initial render

  useEffect(() => {
    setSelectedRanges([]);
  }, [id]); // Empty dependency array ensures that the effect runs once after the initial render

  // useEffect(() => {
  //   generateQueryString(); // Call generateQueryString when selectedFilters change
  // }, [selectedFilters,selectedRanges]); // Add selectedFilters as a dependency

  // Function to filter products based on selected attributes

  const [priceRange, setPriceRange] = useState({ min: 0, max: 100 });

  function handleChange(e) {
    setPriceRange({
      priceRange,
      [e.target.name]: parseInt(e.target.value),
    });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the maximum sale price to determine the range
  const maxSalePrice = Math.max(
    ...products.map((product) => product.salePrice)
  );

  // Determine the number of ranges based on the maximum sale price
  const numRanges = Math.ceil(maxSalePrice / 10000);

  // Generate price ranges dynamically
  const priceRanges = Array.from({ length: numRanges }, (_, index) => ({
    min: index * 10000,
    max: (index + 1) * 10000,
  }));

  // Filter out the price ranges that match the actual sale prices of your products
  const filteredPriceRanges = priceRanges.filter((range) =>
    products.some(
      (product) =>
        product.salePrice >= range.min && product.salePrice < range.max
    )
  );

  const handleCheckpriceboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedRanges([...selectedRanges, value]);
    } else {
      setSelectedRanges(selectedRanges.filter((range) => range !== value));
    }
    setCurrentPage(1);
    // console.log(selectedRanges)
  };

  const addedVariations = new Set();

  const [EnquireShow, setEnquireShow] = useState(false);

  const [EnquireStatus, setEnquireStatus] = useState(false);

  const enquireToggle = () => {
    if (EnquireShow) {
      setEnquireShow(false);
    } else {
      setEnquireShow(true);
    }
  };

  const capitalizeEachWord = (str) => {
    if (!str) return str;
    str = str.replace(/\d+/g, "");
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const validate = (email) => {
    // Regular expression to check for invalid characters
    const invalidCharsRegex = /[^\w@_.]| /; // Disallow spaces and any character that's not a word character, @, or .

    // Check for invalid cases
    if (email === "-" || email === "+") {
      return "";
    } else if (invalidCharsRegex.test(email)) {
      return ""; // Return empty string if invalid characters (including spaces) are found
    } else {
      return email; // Return the original email if valid
    }
  };

  const handleEnquireChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Determine the new value based on input type
    let newValue;
    if (type === "checkbox") {
      newValue = checked;
    } else {
      newValue = value;
    }

    // Capitalize each word for specific fields
    if (name === "fullname") {
      newValue = capitalizeEachWord(newValue);
    }

    if (name === "email") {
      newValue = validate(newValue);
    }

    if (name === "phone") {
      // Replace non-digit characters
      newValue = value.replace(/\D/g, ""); // This allows only digits
      if (newValue.length > 10) {
        newValue = newValue.slice(0, 10); // Limit to 10 digits
      }
    }

    // Update form data with the new value
    setEnquireData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSendEnquire = async (service, Id, userEmail) => {
    // toast.error(`${Id}, ${userEmail}`);
    // if ((!Id, !userEmail)) {
    //   toast.error("Id or Email required");
    //   setEnquireStatus(false);
    //   return;
    // }
    const fields = [
      { name: "fullname", message: "Please enter Full Name" },
      { name: "phone", message: "Please enter phone number" },
      { name: "email", message: "Please enter email" },
    ];

    for (const field of fields) {
      if (!enquireData[field.name]) {
        toast.error(field.message);
        setEnquireStatus(false);
        return;
      }
    }

    const enqdata = {
      ...enquireData,
      userId: Id || null,
      userEmail: userEmail || null,
      type: 1,
      service,
    };

    try {
      setEnquireStatus(true);
      await toast.promise(axiosInstance.post(`/send-enquire`, enqdata), {
        loading: "Enquire Submiting...", // Loading message
        success: "Enquire Submited Successfully!", // Success message
        error: "Failed to Submit Enquire.", // Error message
      });
      if ((Id, userEmail)) {
        enquireToggle();
      }

      setEnquireData((prevData) => ({
        ...prevData,
        fullname: "",
        email: "",
        phone: "",
        service: "",
        QTY: "",
        Requirement: "",
      })); // Clear form fields
    } catch (error) {
      console.error("Failed to send email:", error);
      // toast.error('Failed to send email');
    } finally {
      setEnquireStatus(false);
    }
  };

  return (
    <>
      <Header />

      <Helmet>
        {CatM.metaTitle && <title>{CatM.metaTitle}</title>}
        {CatM.metaDescription && (
          <meta name="description" content={CatM.metaDescription} />
        )}
        {CatM.metaKeywords && (
          <meta name="keywords" content={CatM.metaKeywords} />
        )}
      </Helmet>

      <div className="whitesmoke">
        {/* Page Title */}
        {/* <div
          className="py-4 mb-4 mb-lg-0 white"
          style={{ backgroundColor: "whitesmoke" }}
        >
          <div className="container d-lg-flex justify-content-between align-items-center py-2 py-lg-4">
            <div className="pe-lg-4 text-center text-lg-start">
              <h1 className="h3 mb-0">
                {CatT ? <> {CatT.title} </> : <> </>} Category{" "}
              </h1>
            </div>
            <div className="pt-2 pt-lg-0">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
                  <li className="breadcrumb-item">
                    <Link className="text-nowrap" to="/">
                      <i className="ri-store-2-line" /> Home{" "}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div> */}
        {/* Page Title */}
        {/* Product Category */}
        <div className="container mt-4 pb-4 pb-lg-10">
          <div className="row g-md-4">
            {/* Sidebar */}
            <div className="col-md-4 mystickyFillter">
              <div className=" bg-light p-4 border-bottom mb-2 border rounded leftmybar">
                <h5
                  className="border-bottom pb-2"
                  style={{ textTransform: "uppercase" }}
                >
                  FOR ANY {CatT ? <> {CatT.title} </> : <> </>} ENQUIRY{" "}
                  <i className="ri-chat-3-fill"></i>
                </h5>
                {/* Name input */}
                <div data-mdb-input-init="" className="form-outline mb-4">
                  <label className="form-label" htmlFor="form5Example1">
                    Full Name <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="form5Example1"
                    className="form-control"
                    value={enquireData.fullname}
                    onChange={handleEnquireChange}
                    style={{ boxShadow: "none" }}
                  />
                </div>
                {/* Email input */}
                <div data-mdb-input-init="" className="form-outline mb-4 ">
                  <label className="form-label" htmlFor="form5Example2">
                    Phone No. <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="number"
                    id="form5Example2"
                    className="form-control"
                    name="phone"
                    value={enquireData.phone}
                    onChange={handleEnquireChange}
                    style={{ boxShadow: "none" }}
                  />
                </div>

                <div data-mdb-input-init="" className="form-outline mb-4 ">
                  <label className="form-label" htmlFor="form5Example2">
                    Email ID <span className="text-danger"> (Required )</span>
                  </label>
                  <input
                    type="email"
                    id="form5Example2"
                    className="form-control"
                    name="email"
                    value={enquireData.email}
                    onChange={handleEnquireChange}
                    style={{ boxShadow: "none" }}
                  />
                </div>

                <div data-mdb-input-init="" className="form-outline mb-4 ">
                  <label className="form-label" htmlFor="form5Example2">
                    Requirement{" "}
                    <span className="text-danger"> (Required )</span>
                  </label>
                  <textarea
                    type="texta"
                    id="Requirement"
                    className="form-control"
                    name="Requirement"
                    value={enquireData.Requirement}
                    onChange={handleEnquireChange}
                    style={{ boxShadow: "none" }}
                  />
                </div>

                <div
                  className="d-block"
                  style={{ width: "fit-content !important" }}
                >
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    disabled={EnquireStatus} // Properly use the `disabled` attribute
                    onClick={() => handleSendEnquire(enquireData?.service)} // Wrap the function in an anonymous function
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div
                className={`offcanvas offcanvas-collapse offcanvas-start rounded-3 shadow ${
                  Sidebar && "show"
                }`}
                id="category-sidebar"
                style={{ maxWidth: "23rem" }}
              >
                <div className="offcanvas-header align-items-center shadow-sm">
                  <h2 className="h5 mb-0">Filters</h2>
                  <button
                    className="btn-close ms-auto"
                    type="button"
                    onClick={Closesidebar}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>

                {CatT.filter !== 0 && (
                  <div className="offcanvas-body p-lg-8">
                    {/* Categories */}
                    <div className="widget widget-categories pb-5 mb-5 ">
                      {/* Price Range Slider */}
                      <div className="widget pb-5 mb-5">
                        <h2>Filtered Products</h2>
                        {/* <ul>
          {filteredProducts.map(product => (
            <li key={product._id}>
              <img src={product.pImage} alt={product.title} />
              <p>{product.title}</p>
              <p>{`Sale Price: $${product.salePrice}`}</p>
            </li>
          ))}
        </ul>
 */}

                        {/* <h3 className="widget-title">Price</h3> */}
                        {filteredPriceRanges &&
                          filteredPriceRanges.length > 0 && <b>Price</b>}
                        <ul className="widget-list pt-1">
                          {filteredPriceRanges.map((range, index) => {
                            const isChecked = selectedRanges.includes(
                              `${range.min}-${range.max}`
                            );
                            return (
                              <li key={index} className="mb-1">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={`checkbox-${index}`}
                                    value={`${range.min}-${range.max}`}
                                    onChange={handleCheckpriceboxChange}
                                    checked={isChecked}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`checkbox-${index}`}
                                  >
                                    ₹{range.min} - ₹{range.max}
                                  </label>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      {/* Price Range Slider */}
                      {/* <h3 className="widget-title">Categories</h3> */}

                      {/* Product category */}

                      {Attr.map((attr, index) => {
                        let hasVariations = false;

                        const checkVariationsExist = () => {
                          // for (const product of products) {
                          //   if (product.variations?.Color?.length > 0) {
                          //     return true;
                          //   }
                          // }
                          return true;
                        };

                        if (checkVariationsExist()) {
                          hasVariations = products.some(
                            (product) =>
                              product?.variations &&
                              product.variations[attr.name] !== undefined
                          );
                        }

                        if (hasVariations) {
                          return (
                            <div className="accordion-item" key={attr._id}>
                              <h2
                                className="accordion-header"
                                id={`heading${index + 1}`}
                              >
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index + 1}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse${index + 1}`}
                                >
                                  {attr.name}
                                </button>
                              </h2>
                            </div>
                          );
                        }
                      })}
                    </div>
                    {/* Categories */}
                  </div>
                )}
              </div>
            </div>
            {/* Sidebar */}
            {/* Content */}

            <aside className="col-md-8" style={{ flex: 1 }}>
              {CatImage !== undefined && CatImage !== "" ? (
                <>
                  <div
                    className="bg-image p-4"
                    style={{
                      backgroundImage: `url('${CatImage}'`,
                      aspectRatio: "5/1",
                    }}
                  >
                    <h2 className="text-white">{CatT.slide_head}</h2>
                    <p className="text-white">{CatT.slide_para}</p>
                  </div>{" "}
                </>
              ) : (
                <div
                  className=" skeleton w-100 h-auto"
                  style={{ aspectRatio: "5/1", objectFit: "cover" }}
                />
              )}

              <div className="row mt-4">
                {isCat
                  ? Array.from({ length: 4 }).map((_, index) => (
                      <div className="col-md-3 col-6">
                        <div
                          className="card-1 skeleton mb-2"
                          style={{ height: 200, borderRadius: 10 }}
                        ></div>
                      </div>
                    ))
                  : Cat.length > 0 &&
                    Cat.map((Cat) => (
                      <>
                        <div className="col-md-3 col-6">
                          <div className="p-0 bg-white rounded">
                            <Link className="col py-2 " to={`/${Cat.slug}`}>
                              <img
                                alt="ICU At Home"
                                width="100%"
                                className="rounded"
                                src={Cat.image}
                                style={{
                                  width: "100%",
                                  objectFit: "cover",
                                  aspectRatio: "1 / 1",
                                }}
                              />
                              <h5 className="text-center text-primary fs-lg px-2 py-2">
                                {" "}
                                {Cat.title}{" "}
                              </h5>
                            </Link>
                          </div>
                        </div>
                      </>
                    ))}
              </div>
              {/* Recommended Products Slider */}
              {/* Toolbar */}
              {CatT.filter !== 0 && (
                <div
                  className="card mt-5 mb-4  "
                  style={{ backgroundColor: "white" }}
                >
                  <div className=" px-3   rounded border">
                    <div className="card-body py-2 m ">
                      <div className="d-flex align-items-center justify-content-between">
                        <form className="row row-cols-auto g-4 align-items-center me-3">
                          <div className="col">
                            <div className="row g-2">
                              <label
                                htmlFor="sorting"
                                className="col-auto col-form-label d-none d-sm-block"
                              >
                                No of products
                              </label>
                              <div className="col-auto">
                                <select
                                  className="form-select"
                                  id="sorting"
                                  value={count}
                                  onChange={handlecountChange}
                                >
                                  <option value="50">50</option>
                                  <option value="100">100</option>

                                  <option value="200">200</option>

                                  <option value="500">500</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                        <ul
                          className="nav nav-pills flex-nowrap"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item d-lg-none"
                            role="presentation"
                          >
                            <button
                              onClick={Opensidebar}
                              className="nav-link active py-2 px-3"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Fillter{" "}
                              <i className="ri-function-line fs-5 ms-2" />
                            </button>
                          </li>

                          {/* <li className="nav-item" role="presentation">
                        <button
                          className="nav-link py-2 px-3"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          <i className="ri-list-check fs-5" />
                        </button>
                      </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Toolbar */}
              {Viewfillter && (
                <div className="w-100">
                  {(() => {
                    try {
                      const parsedFilters = JSON.parse(Viewfillter);
                      return Object.entries(parsedFilters).map(
                        ([key, value]) => (
                          <span key={key} className="w-100">
                            {value.split(",").map((val) => (
                              <button
                                onClick={() => removeFilter({ key, val })}
                                className="btn btn-sm btn-secondary py-1 px-2 ms-1 mb-2"
                                key={`${key}-${val}`}
                              >
                                {`${key}: ${val}`} <i class="ri-close-line"></i>
                              </button>
                            ))}
                          </span>
                        )
                      );
                    } catch (error) {
                      console.error("Error parsing Viewfillter:", error);
                      return <div>No filters Found</div>;
                    }
                  })()}
                </div>
              )}

              {/* <h2 className="h3 border-bottom pb-4 mb-4">
                Results for   {CatT ? <>  {CatT}  </> : <> loading.. </>}
              </h2> */}

              {CatT.filter === 0 && (
                <div className="accordion-body mt-4">
                  <div dangerouslySetInnerHTML={{ __html: CatT.description }} />
                </div>
              )}

              {/* Heading */}
              <div
                className={`tab-content ${CatT.filter === 0 && "d-none"}`}
                id="pills-tabContent"
              >
                {/* Product Grid */}
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  {/* Product Cards */}
                  <div className="row pt-2 mx-n2 mb-4 mb-sm-8">
                    {isCat
                      ? Array.from({ length: 6 }).map((_, index) => (
                          <div
                            key={index}
                            className="col-lg-3 col-md-3 col-sm-6 mt-4"
                          >
                            <div
                              className="card-1 skeleton"
                              style={{ height: 430, borderRadius: 5 }}
                            ></div>
                          </div>
                        ))
                      : Pro.map((Pro) => {
                          // Calculate average rating for the current product
                          const productRatings = ratings.filter(
                            (rating) => rating.productId === Pro._id
                          );
                          const totalRatings = productRatings.length;
                          const totalRatingValue = productRatings.reduce(
                            (acc, curr) => acc + curr.rating,
                            0
                          );
                          const averageRating =
                            totalRatings > 0
                              ? totalRatingValue / totalRatings
                              : 0;
                          const myslug = CreateSlug(Pro.title); // Make sure `data` and `Product` are defined and available in the scope.

                          return (
                            <>
                              {/* Product */}
                              <div className="col-sm-6 col-6 col-md-3 px-2 mb-4">
                                <div className="card card-product h-100">
                                  {/* Badges */}
                                  <div className="product-badges">
                                    <span className="badge bg-danger">12%</span>
                                    <span className="badge bg-success">
                                      Top
                                    </span>
                                  </div>
                                  {/* Badges */}
                                  {/* Buttons */}
                                  <div className="product-buttons">
                                    <button
                                      className="btn-product btn-wishlist"
                                      type="button"
                                      data-bs-toggle="button"
                                      title="Add to wishlist"
                                    >
                                      <i className="ri-heart-line" />
                                    </button>
                                    <Link
                                      className="btn-product btn-compare"
                                      href="#"
                                      title="Compare product"
                                    >
                                      <i className="ri-repeat-line" />
                                    </Link>
                                  </div>
                                  {/* Buttons */}
                                  {/* Preview Image */}
                                  <Link
                                    to={`/product/${myslug}/`}
                                    className="card-img-top d-block overflow-hidden flex-shrink-0"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={Pro.pImage}
                                      alt="Product"
                                    />
                                  </Link>
                                  {/* Preview Image */}
                                  <div className="card-body d-flex flex-column align-items-start flex-grow-1 h-100 py-3 border-top">
                                    {/* Product Category */}

                                    {/* Product Category */}
                                    {/* Product Title */}
                                    <h3 className="product-title ">
                                      <Link to={`/product/${myslug}`}>
                                        {" "}
                                        {Pro.title}{" "}
                                      </Link>
                                    </h3>

                                    {/* Product Title */}
                                    {/* Star Rating */}
                                    <span
                                      className={` d-none star-rating star-${
                                        Math.round(averageRating) * 2
                                      }`}
                                    />

                                    {/* Star Rating */}
                                    {/* Product Price */}
                                    <div className="product-price">
                                      {Pro.salePrice === 0 ? (
                                        <span
                                          className="text-danger"
                                          style={{ color: "red" }}
                                        >
                                          Price On request
                                        </span>
                                      ) : (
                                        <span className="text-danger fs-5">
                                          ₹{Pro.salePrice}
                                          <del className="text-body-secondary ms-1">
                                            <small>₹{Pro.regularPrice}</small>
                                          </del>
                                        </span>
                                      )}
                                    </div>
                                    {/* Product Price */}
                                    {/* Product Meta */}

                                    {/* Product Meta */}
                                  </div>
                                  {Pro.userId && (
                                    <div className="card-body py-3 border-top">
                                      <p
                                        className="font-4 text-center mb-0"
                                        style={{ fontSize: 12 }}
                                      >
                                        {Pro.userId && Pro.userId.username}{" "}
                                        {Pro.userId.verified === 1 && (
                                          <svg
                                            className="ms-1"
                                            width={15}
                                            height={15}
                                            id="Layer_1"
                                            data-name="Layer 1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 122.88 116.87"
                                          >
                                            <defs>
                                              <style
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    ".cls-1{fill:#10a64a;fill-rule:evenodd;}.cls-2{fill:#fff;}",
                                                }}
                                              />
                                            </defs>
                                            <polygon
                                              className="cls-1"
                                              points="61.37 8.24 80.43 0 90.88 17.79 111.15 22.32 109.15 42.85 122.88 58.43 109.2 73.87 111.15 94.55 91 99 80.43 116.87 61.51 108.62 42.45 116.87 32 99.08 11.73 94.55 13.73 74.01 0 58.43 13.68 42.99 11.73 22.32 31.88 17.87 42.45 0 61.37 8.24 61.37 8.24"
                                            />
                                            <path
                                              className="cls-2"
                                              d="M37.92,65c-6.07-6.53,3.25-16.26,10-10.1,2.38,2.17,5.84,5.34,8.24,7.49L74.66,39.66C81.1,33,91.27,42.78,84.91,49.48L61.67,77.2a7.13,7.13,0,0,1-9.9.44C47.83,73.89,42.05,68.5,37.92,65Z"
                                            />
                                          </svg>
                                        )}
                                      </p>

                                      <button
                                        style={{ fontSize: "12px" }}
                                        className="btn btn-primary d-flex align-items-center justify-content-center w-100 mt-4 p-2"
                                        type="button"
                                        data-toggle="modal"
                                        data-target={`#exampleModal${Pro._id}`}
                                        onClick={enquireToggle}
                                      >
                                        <i className="ri-mail-send-fill"></i>
                                        <span className="ms-2">
                                          Enquiry Now
                                        </span>
                                      </button>

                                      <div
                                        className="modal enquire fade show"
                                        style={{
                                          display: EnquireShow
                                            ? "block"
                                            : "none",
                                        }}
                                        id={`exampleModal${Pro._id}`}
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog modal-dialog-centered"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header py-0">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                {Pro.title}
                                              </h5>
                                              <button
                                                type="button"
                                                className="border-0 close bg-transparent h3"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                                onClick={enquireToggle}
                                              >
                                                <span aria-hidden="true">
                                                  ×
                                                </span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <div className="d-block">
                                                {/* Name input */}
                                                <div
                                                  data-mdb-input-init=""
                                                  className="form-outline mb-4"
                                                >
                                                  <label
                                                    className="form-label"
                                                    htmlFor="form5Example1"
                                                  >
                                                    Full Name{" "}
                                                    <span className="text-danger">
                                                      {" "}
                                                      (Required )
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="text"
                                                    name="fullname"
                                                    id="form5Example1"
                                                    className="form-control"
                                                    value={enquireData.fullname}
                                                    onChange={
                                                      handleEnquireChange
                                                    }
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                                {/* Email input */}
                                                <div
                                                  data-mdb-input-init=""
                                                  className="form-outline mb-4"
                                                >
                                                  <label
                                                    className="form-label"
                                                    htmlFor="form5Example2"
                                                  >
                                                    Phone No.{" "}
                                                    <span className="text-danger">
                                                      {" "}
                                                      (Required )
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="number"
                                                    id="form5Example2"
                                                    className="form-control"
                                                    name="phone"
                                                    value={enquireData.phone}
                                                    onChange={
                                                      handleEnquireChange
                                                    }
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  data-mdb-input-init=""
                                                  className="form-outline mb-4"
                                                >
                                                  <label
                                                    className="form-label"
                                                    htmlFor="form5Example2"
                                                  >
                                                    Email address{" "}
                                                    <span className="text-danger">
                                                      {" "}
                                                      (Required )
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="email"
                                                    id="form5Example2"
                                                    className="form-control"
                                                    name="email"
                                                    value={enquireData.email}
                                                    onChange={
                                                      handleEnquireChange
                                                    }
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>

                                                <div
                                                  data-mdb-input-init=""
                                                  className="form-outline mb-4"
                                                >
                                                  <label
                                                    className="form-label"
                                                    htmlFor="form5Example2"
                                                  >
                                                    QTY{" "}
                                                    <span className="text-danger">
                                                      {" "}
                                                      (Required )
                                                    </span>
                                                  </label>
                                                  <input
                                                    type="number"
                                                    id="QTY"
                                                    className="form-control"
                                                    name="QTY"
                                                    value={enquireData.QTY}
                                                    onChange={
                                                      handleEnquireChange
                                                    }
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                  <input
                                                    type="text"
                                                    id="service"
                                                    value={""}
                                                    className="form-control d-none"
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-dismiss="modal"
                                                onClick={enquireToggle}
                                              >
                                                Close
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() =>
                                                  handleSendEnquire(
                                                    Pro.title,
                                                    Pro.userId._id,
                                                    Pro.userId.email
                                                  )
                                                } // Wrap the function in an anonymous function
                                                disabled={EnquireStatus} // Properly use the `disabled` attribute
                                              >
                                                Submit
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {/* Product Addon */}

                                  {/* Product Addon */}
                                </div>
                              </div>
                              {/* Product */}
                            </>
                          );
                        })}

                    {Pro.length === 0 && !isCat && (
                      <div
                        className="container mx-auto my-2 mt-5 py-2 bg-white text-center"
                        style={{
                          height: 193,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>No products found for this category.</p>
                      </div>
                    )}

                    <hr className="d-none d-sm-block" />
                    {/* Pagination */}
                    {/* 
              <div className="d-flex justify-content-between mt-6">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="ri-arrow-left-s-line me-1" />
                      Prev
                    </a>
                  </li>
                </ul>
                <ul className="pagination">
                  <li className="page-item d-sm-none">
                    <span className="page-link page-link-static">1 / 5</span>
                  </li>
                  <li
                    className="page-item active d-none d-sm-block"
                    aria-current="page"
                  >
                    <span className="page-link">
                      1<span className="visually-hidden">(current)</span>
                    </span>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a className="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li className="page-item d-none d-sm-block">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                </ul>
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      Next
                      <i className="ms-1 ri-arrow-right-s-line" />
                    </a>
                  </li>
                </ul>
              </div> */}

                    <div className="d-flex justify-content-between mt-6">
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            type="button"
                            onClick={() => handlePageChange(currentPage - 1)}
                          >
                            <i className="ri-arrow-left-s-line me-1"></i>Prev
                          </button>
                        </li>
                      </ul>
                      {/* <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
              <button className="page-link"  type="button" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
            </li>
          ))}
        </ul> */}
                      <ul className="pagination">
                        <li
                          className={`page-item ${
                            totalPages === 0 ? "disabled" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            type="button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            aria-label="Next"
                          >
                            Next<i className="ms-1 ri-arrow-right-s-line"></i>
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* Pagination */}
                  </div>
                  {/* Product Cards */}
                </div>
                {/* Product Grid */}
                {/* Product List */}

                {/* Product List */}
              </div>
            </aside>
            {/* Content */}
          </div>
          {CatT.filter !== 0 && (
            <div className="accordion-body mt-4">
              <div dangerouslySetInnerHTML={{ __html: CatT.description }} />
            </div>
          )}

          {Specifications.length !== 0 &&
            Specifications.map((specification, specIndex) => (
              <>
                <section className="my-4 py-4">
                  <h3 className="text-start mb-3">{specification.heading}</h3>
                  <div className="accordion" id={`accordionID` + specIndex}>
                    {specification.labels.map((label, labelIndex) => (
                      <>
                        <div
                          className="accordion-item mb-2 border rounded overflow-hidden"
                          key={labelIndex}
                        >
                          <div className="accordion-header">
                            <h4
                              style={{ fontSize: 20, fontWeight: 600 }}
                              className="accordion-button h3 collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${labelIndex}`}
                              aria-expanded="false"
                              aria-controls={`#collapseOne${labelIndex}`}
                            >
                              {label.label}
                            </h4>
                          </div>
                          <div
                            id={`collapseOne${labelIndex}`}
                            className="accordion-collapse collapse"
                            data-bs-parent={`#accordionID${specIndex}`}
                          >
                            <div className="accordion-body">{label.value}</div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </section>
              </>
            ))}

          {/* Product Cards */}
          <div className="container pb-4 pb-lg-10">
            {/* Heading */}
            <div className="d-flex flex-wrap justify-content-between align-items-center pt-1 border-bottom pb-4 mb-4">
              <h2 className="h3 mb-0 me-2">Recommended Products</h2>
              <div className="ms-n4">
                {/* <a
                className="btn btn-sm btn-link link-info link-hover-primary d-flex align-items-center bg-btn-new"
                href="#"
              >
                View All
                <i className="ri-arrow-right-line ms-1" />
              </a> */}
              </div>
            </div>
            {/* Heading */}
            {/* Product Cards */}
            <div className="row pt-2 mx-n2 hero-swiper hide-desk-arrow">
              {/* Product Card */}
              <Swiper
                breakpoints={{
                  300: {
                    slidesPerView: 2,
                    spaceBetween: 10, // Set the gap between slides for window width <= 400px
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20, // Set the gap between slides for window width <= 768px
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 25, // Set the gap between slides for window width <= 992px
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 20, // Set the gap between slides for window width <= 1200px
                  },
                }}
                pagination={true}
                modules={[Pagination, Navigation]}
                className="swiper-wrapper"
              >
                {isProducts
                  ? Array.from({ length: 7 }).map((_, index) => (
                      <SwiperSlide key={index}>
                        <div
                          className="card-1 skeleton"
                          style={{ height: 371, borderRadius: 10 }}
                        ></div>
                      </SwiperSlide>
                    ))
                  : layout.recommended_products && (
                      <>
                        {RProducts.map((product, index) => {
                          const productRatings = ratings.filter(
                            (rating) => rating.productId === product._id
                          );
                          const totalRatings = productRatings.length;
                          const totalRatingValue = productRatings.reduce(
                            (acc, curr) => acc + curr.rating,
                            0
                          );
                          const averageRating =
                            totalRatings > 0
                              ? totalRatingValue / totalRatings
                              : 0;

                          console.log("layout.trending_product.", layout);
                          const myslug = CreateSlug(product.title); // Make sure `data` and `Product` are defined and available in the scope.

                          return (
                            layout.recommended_products.includes(
                              product._id
                            ) && (
                              <>
                                <SwiperSlide key={index}>
                                  <div className="card card-product h-100">
                                    {/* Badges */}
                                    <div className="product-badges">
                                      <span className="badge bg-danger">
                                        12%
                                      </span>
                                      <span className="badge bg-success">
                                        Top
                                      </span>
                                    </div>
                                    {/* Badges */}
                                    {/* Buttons */}
                                    <div className="product-buttons">
                                      <button
                                        className="btn-product btn-wishlist"
                                        type="button"
                                        data-bs-toggle="button"
                                        title="Add to wishlist"
                                      >
                                        <i className="ri-heart-line" />
                                      </button>
                                      <a
                                        className="btn-product btn-compare"
                                        href="#"
                                        title="Compare product"
                                      >
                                        <i className="ri-repeat-line" />
                                      </a>
                                      <a
                                        className="btn-product btn-view"
                                        href="#modal-quick-view"
                                        data-bs-toggle="modal"
                                        title="Quick preview"
                                      >
                                        <i className="ri-eye-line" />
                                      </a>
                                    </div>
                                    {/* Buttons */}
                                    {/* Preview Image */}
                                    <Link
                                      className="card-img-top d-block overflow-hidden flex-shrink-0"
                                      to={`/product/${myslug}`}
                                    >
                                      <img
                                        className="img-fluid"
                                        src={product.pImage}
                                        alt="product_image"
                                      />
                                    </Link>
                                    {/* Preview Image */}
                                    <div className="card-body d-flex flex-column align-items-start flex-grow-1 rounded-bottom h-100 py-3">
                                      {/* Product Category */}

                                      {/* Product Category */}
                                      {/* Product Title */}
                                      <h3 className="product-title flex-grow-1">
                                        <Link
                                          to={`/product/${myslug}/${product._id}`}
                                        >
                                          {" "}
                                          {product.title}{" "}
                                        </Link>
                                      </h3>
                                      {/* Product Title */}
                                      {/* Star Rating */}
                                      {/* <span className={`star-rating star-${Math.round(averageRating) * 2}`} /> */}

                                      {/* Star Rating */}
                                      {/* Product Price */}
                                      <div className="product-price">
                                        {product.salePrice === 0 ? (
                                          <span
                                            className="text-danger"
                                            style={{ color: "red" }}
                                          >
                                            Price On request
                                          </span>
                                        ) : (
                                          <span className="text-danger fs-5">
                                            ₹{product.salePrice}
                                            <del className="text-body-secondary ms-1">
                                              <small>
                                                ₹{product.regularPrice}
                                              </small>
                                            </del>
                                          </span>
                                        )}
                                      </div>
                                      {/* Product Price */}
                                      {/* Product Meta */}

                                      {/* Product Meta */}
                                    </div>
                                    {/* Product Addon */}

                                    {/* Product Addon */}
                                  </div>
                                  {/* Product Cards */}
                                </SwiperSlide>
                              </>
                            )
                          );
                        })}
                      </>
                    )}
              </Swiper>
            </div>
            {/* Product Cards */}
          </div>
          {/* Product Cards */}
        </div>

        {/* Product Category */}
      </div>

      <Footer />
    </>
  );
};

export default Category;
